<template>
  <div class="page_comsxlisttable" style="width: 100%;height: 100%;">
    <el-dialog :title="dialogTitle" :append-to-body="true" :visible.sync="visiable" width="800px"
      custom-class="width_800 cus_dialog" @close="onClose">


      <!--      <el-row style="margin-bottom: 10px;width: 100%;" class="">-->
      <!--        <div class="flex_1">-->
      <!--          <el-input v-model="searchForm.mohuTitle" size="small" clearable style="width: 200px!important;"-->
      <!--                    placeholder="模糊搜索">-->
      <!--            <el-button slot="append" size="small" icon="el-icon-search" @click="getList"></el-button>-->
      <!--          </el-input>-->
      <!--        </div>-->
      <!--      </el-row>-->
      <el-table :data="DataList" height="calc( 100% - 100px)" style="width: 100%;" stripe border>
        <el-table-column type="index" label="编号" width="50"></el-table-column>
        <el-table-column label="描述" :show-overflow-tooltip="true" min-width="200">
          <template slot-scope="scope">
            {{ scope.row.status_type == 'r' ? scope.row.czr_username : '' }}
            {{ scope.row.status_type == 'l' ? scope.row.sl_username : '' }}
            {{ scope.row.status_type != 'r' && scope.row.status_type != 'l' ? scope.row.czr_username : '' }}

            {{scope.row.status_typeName}}
            {{ !scope.row.status_typeName && scope.row.status_type == 'a' ?'申购':''}}
            {{scope.row.title}}×{{scope.row.absVal}}
          </template>
        </el-table-column>
        <el-table-column prop="cdate" label="变更日期" width="150" />
        <el-table-column prop="old_num" label="变更前数量" width="120" />
        <el-table-column prop="new_num" label="变更后数量" width="120"></el-table-column>
      </el-table>
      <el-pagination class="cus_page" @size-change="handleSizeChange" @current-change="paginate"
        :current-page.sync="page.current_page" :page-sizes="[10, 50, 100]" :page-size="page.per_page"
        layout="total,sizes, prev, pager, next" :total="page.count">
      </el-pagination>

      <div slot="footer">
        <el-button type="primary" @click="onClose" size="small" plain>关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  components: {},
  props:['dialogVisible_logList','dialogTitle','EditItem'],
  data() {
    return {
      searchForm: {},
      DataList: [],
      multipleSelection: [],
      page: {
        current_page: 1,
        per_page: 50,
        count: 0,
        pageSizes: [10, 50, 100]
      },
      visiable:false,
    }
  },
  mounted() {
    this.getList();
    this.visiable =this.dialogVisible_logList
  },
  methods: {
    getList() {
      let _this = this
      let params = {}, arr = []
      params.page = _this.page.current_page ? _this.page.current_page : 1
      params.pagesize = _this.page.per_page ? _this.page.per_page : 50
      params.type = '1'
      params.cl_id = this.EditItem.id
      this.$http.post("/api/upd_hc_log_list", params).then(res => {
        if(res.data.data&&res.data.data.length>0){
          res.data.data.map(a=>{
            if(a.remark){
              a.zdjson = JSON.parse(a.remark)
            }
            let mss = a.new_num - a.old_num;
            let absVal = Math.abs(mss);
            a.absVal = absVal
          })
          setTimeout(function (){
            _this.DataList = res.data.data;
            // console.log(131,_this.DataList)
          },50)
        }

        if(res.data&&res.data.page){
          this.page = res.data.page
          this.page_hc = res.data.page
        }
      })
    },

    onSearch() {
      this.page.current_page = 1
      this.getList()
    },

    chooseHC() {
      let _this = this
      let flag = 0
      if (_this.multipleSelection.length == 0) {
        _this.$message.warning('请选择至少一条耗材')
      } else {
        _this.multipleSelection.map(row=>{
          flag+=1
          _this.$$parent(_this, 'chooseHC', row)
        })
        if(flag == _this.multipleSelection.length){
          this.onClose()
        }
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(val)
    },
    onClose(){
      this.visiable = false
      this.$$parent(this,'closeLog')
    },
    handleSizeChange(val) {
      this.page.current_page = 1
      this.page.per_page = val
      this.getList();
    },
    paginate(val) {
      this.page.current_page = val
      this.getList();
    },
  }
}
</script>

<style lang="less" type="text/less">
.page_comsxlisttable {
  .el-table__expanded-cell {
    padding: 8px 8px 6px 68px;
  }

  .demo-table-expand {
    font-size: 14px;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .special .el-form-item{
    width: 100% !important;

  }
}
</style>